
import optionsData from '@/data/options.json';
export default {
  props:{
    region:{
      type:String,
      default:'UK',
    },
  },
  data(){
      return{
        options: optionsData
      }
    },
  computed: {
    backgroundStyles() {
      const imgUrl = this.$img('https://cdn-wp.tefl.org/wp-content/uploads/2023/11/bf-bg-mobile.jpg', { width: '100%',format:'webp',quality:50 })
      return {
        backgroundImage: `url('${imgUrl}')`
      }
    },
    early() {
      const d = new Date();
      const bfDate = new Date('2023-12-01')
      if (d < bfDate){
        return true;
      } else {
        return false
      }
    },
    currentMonth() {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const d = new Date();
      const name = month[d.getMonth()];
      return name
    }
  }
}
