
export default {
    props: {
        id: {
            default: '',
            type: String,
        },
        header: {
            default: 'true',
            type: String,
        },
        classes: {
            default: 'greybg padding whychoosetefl',
            type: String,
        },
        region: {
            default: 'uk',
            type: String,
        }
    },
    data(){
        return{
             isVisible:false
        }
    },
    methods:{
        visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
      console.log(isVisible, entry)
    }
  
    }
}
